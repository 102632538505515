.btn {
  font-size: 0.875rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  padding: 15px 20px 12px;
  border-radius: 4px;
  &.btn-primary {
    background-color: #86c2ca;
    color: #ffffff;
  }
  &.btn-secondary {
    background-color: #32312f;
    color: #ffffff;
  }
  &.btn-danger {
    background-color: #b34045;
    color: #ffffff;
  }
}
