/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

//font
@font-face {
  font-family: FoundersGrotesk;
  src: url(assets/fonts/Font_FoundersGrotesk/FoundersGrotesk-Regular.otf)
    format("opentype");
}

* {
  font-family: "FoundersGrotesk", sans-serif;
}

@import "./assets/styles/buttons.scss";

$primary: #86c2ca;
$secondary: #86c2ca;
$white: #fff;
$gray: #9b9b9b;

// @import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  .ng-clear-wrapper {
    color: #999;
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    .ng-arrow {
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px;
  }

  .ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;
    z-index: 100;
  }

  .ng-dropdown-panel .scroll-host {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    display: block;
    -webkit-overflow-scrolling: touch;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items {
    display: block;
    height: auto;
    box-sizing: border-box;
    max-height: 240px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 10px;
  }
}

.form-control {
  position: relative;
  padding: 15px 15px 0 15px;
  margin-bottom: 15px;
  position: relative;
  error-field {
    position: absolute;
    bottom: -22px;
    left: 15px;
  }
}

.form-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1rem;
  // color: $white;
  color: rgb(75 85 99);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form-label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
    white-space: nowrap;
  }
}

.form-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.9rem;
  color: $gray;
}

.form-field:focus {
  ~ .form-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.9rem;
    color: $primary;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 600;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}

.spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #d07d43;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* reset input */
.form-field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
/* demo */
